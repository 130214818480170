<template>
  <div class="page-container">
    <progress-step :step="page.step"></progress-step>
    <div class="page-content">
      <v-card flat>
        <v-card-text>
          <div class="bold-f-s">Are your employment details correct?</div>
        </v-card-text>
        <v-card-text>
          <div class="employment">
            <v-row>
              <v-col v-for="(field, index) in employer" :key="index"
                     cols="12">
                <ValidationProvider :name="field.title" :rules="field.rules" v-slot="{ errors }" style="width: 100%">
                  <v-select
                    v-if="field.filedKey === 'paydayOnHoliday'"
                    height="20px"
                    :items="field.options[field.filedKey]"
                    :label="field.title"
                    v-model="employment[field.filedKey]"
                    :error-messages="errors[0]"
                  ></v-select>
                  <v-text-field
                    v-else-if="field.filedKey === 'employerPhone'"
                    height="20px"
                    :label="field.title"
                    v-mask="mask"
                    v-model="employment[field.filedKey]"
                    :error-messages="errors[0]"
                  ></v-text-field>
                  <v-text-field
                    v-else
                    height="20px"
                    :label="field.title"
                    v-model="employment[field.filedKey]"
                    :error-messages="errors[0]"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-text>
          <div style="font-size: 12px;color:#B3B7C3">
            By <span style="color: #EB5757">keeping all data accurate</span>, our team can work on funding your loan as quickly as possible.
          </div>
        </v-card-text>
      </v-card>
    </div>
    <div class="page-action">
      <v-btn
        large
        block
        color="primary"
        @click="next">
        Next
      </v-btn>
    </div>
    <v-snackbar
      color="error"
      v-model="snackbar.display"
      multi-line
    >
      <v-card-text class="p-0" style="font-size: 18px">{{snackbar.message}}</v-card-text>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar.display = false"
        >
          <v-card-text class="p-0" style="font-size: 18px">Close</v-card-text>
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay
      absolute
      :value="overlay"
    >
      <v-icon class="mdi mdi-spin mdi-loading" large></v-icon>
    </v-overlay>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { DataType, Tool } from '../../js/core'
import { OriginationApi } from '../../api/application'
import ProgressStep from '../../components/progress/ProgressStep'

const tool = new Tool()
export default {
  components: { ProgressStep, ValidationProvider },
  data () {
    return {
      page: DataType.PAGES.EMPLOYMENT,
      snackbar: {
        display: false
      },
      employer: [
        { title: 'Employer Name', filedKey: 'employerName', rules: 'required' },
        { title: 'Job Title', filedKey: 'jobTitle', rules: 'required' },
        { title: 'Employer Phone Number', filedKey: 'employerPhone', rules: 'phone' },
        {
          title: 'Please select your Payday on Holiday',
          filedKey: 'paydayOnHoliday',
          options: { paydayOnHoliday: DataType.PaydayOnHolidayType },
          rules: 'required'
        }
      ],
      employment: {
        employerName: '',
        jobTitle: '',
        employerPhone: '',
        paydayOnHoliday: ''
      },
      sourceEmployment: {},
      personal: {},
      overlay: true,
      mask: ''
    }
  },
  watch: {
    employment (value) {
    }
  },
  async created () {
    const _this = this
    _this.$store.commit('setCurrentPage', _this.page)
    _this.$eventBus.$emit('initTimeOut')
    // TODO get employment information
    await OriginationApi.getLoanById({
      loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
      feature: 'EMPLOYMENT'
    }, function (result) {
      _this.sourceEmployment = result
      for (const key in _this.employment) {
        _this.employment[key] = result[key]
      }
      if (result.jobTitle === 'Unknown') {
        _this.employment.jobTitle = ''
      }
      _this.overlay = false
    }, function (res) {
    })
    _this.mask = '(###) ###-####'
    this.personal = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PERSONAL))
    //
    OriginationApi.pageControl(null, function (pageControl) {
      if (
        pageControl !== null &&
        pageControl !== undefined &&
        pageControl.indexOf(localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)) > 0
      ) {
        _this.page.previous = 'PERSONAL'
        _this.$store.commit('setCurrentPage', _this.page)
      }
    })
  },
  methods: {
    verifyEmploymentInformation () {
      const _this = this
      const employerName = _this.employment.employerName
      if (tool.isEmpty(employerName)) {
        _this.snackbar.message = 'Invalid Employer Name!'
        _this.snackbar.display = true
        _this.overlay = false
        return false
      }
      const jobTitle = _this.employment.jobTitle
      if (tool.isEmpty(jobTitle) || jobTitle === 'Unknown') {
        _this.snackbar.message = 'Invalid Job Title!'
        _this.snackbar.display = true
        _this.overlay = false
        return false
      }
      const employerPhone = _this.employment.employerPhone
      if (employerPhone.length !== 14) {
        _this.snackbar.message = 'Invalid Employer Phone Number!'
        _this.snackbar.display = true
        _this.overlay = false
        return false
      }
      const paydayOnHoliday = _this.employment.paydayOnHoliday
      if (tool.isEmpty(paydayOnHoliday)) {
        _this.snackbar.message = 'Invalid Payday on Holiday!'
        _this.snackbar.display = true
        _this.overlay = false
        return false
      }
      return true
    },
    back () {
      this.$router.push('/authentication/profile')
    },
    next () {
      const _this = this
      _this.overlay = true
      if (!_this.verifyEmploymentInformation()) {
        return false
      }
      const updateEmployment = {}
      for (const key in _this.employment) {
        let value = _this.employment[key]
        if (key === 'employerPhone') {
          value = value.replace(/[^0-9]/ig, '')
        }
        if (value !== this.sourceEmployment[key]) {
          updateEmployment[key] = value
        }
      }
      if (tool.isEmpty(updateEmployment)) {
        const stepMessage = {
          currect: DataType.PAGES.EMPLOYMENT,
          next: DataType.PAGES.BANK
        }
        _this.$eventBus.$emit('sendStepToSloth', stepMessage)
        OriginationApi.enteredAndLeft(
          localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
          DataType.PAGES.EMPLOYMENT.step,
          DataType.PAGES.BANK.step,
          localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
        )
        _this.$router.push('/authentication/bank')
      } else {
        updateEmployment.loanId = localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID)
        OriginationApi.updateOrigination({ feature: 'employment', data: updateEmployment }, function (result) {
          _this.overlay = true
          const stepMessage = {
            currect: DataType.PAGES.EMPLOYMENT,
            next: DataType.PAGES.BANK
          }
          _this.$eventBus.$emit('sendStepToSloth', stepMessage)
          OriginationApi.enteredAndLeft(
            localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
            DataType.PAGES.EMPLOYMENT.step,
            DataType.PAGES.BANK.step,
            localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
          )
          _this.$router.push('/authentication/bank')
        }, function () {
          _this.$router.push('/notification/-400')
        })
      }
      _this.$eventBus.$emit('closeCountDown')
    }
  }
}
</script>
<style lang="css">
  .employment input {
    text-align: left !important;
    font-size: 16px !important;
  }
</style>
